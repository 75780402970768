import ReactGA from "react-ga"

const track = (src, event, value) => {
  ReactGA.event({
    category: src,
    action: event,
    label: value,
    noninteraction: event === "navigate" ? false : true
  })
}

export default track
